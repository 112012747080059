const pkg: Record<string, any> = {
    name: "CRMApplicationManagement",
    path: "/app-marketplace",
    component: "basic",
    meta: {
        title: "CRMApplicationManagement",
        i18nTitle: "Routes.CRMApplicationManagement.list",
        requiresAuth: true,
        keepAlive: true,
        icon: "fluent:book-information-24-regular",
        order: 2,
    },
    children: [
        {
            name: "CRMApplicationManagement_list",
            path: "/CRMApplicationManagement/list",
            component: "self",
            meta: {
                title: "marketplace",
                i18nTitle: "Routes.CRMApplicationManagement.list",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
            },
        },
        {
            name: "CRMApplicationManagement_detail",
            path: "/CRMApplicationManagement/detail",
            component: "self",
            meta: {
                title: "tenant_detail",
                i18nTitle: "Routes.CRMApplicationManagement.detail",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
                hide: true,
            },
        },
    ],
}

export default pkg
