const pkg: Record<string, any> = {
    name: "version",
    path: "/app-version",
    component: "basic",
    meta: {
        title: "版本管理",
        i18nTitle: "Routes.version._value",
        requiresAuth: true,
        keepAlive: true,
        icon: "fluent:book-information-24-regular",
        order: 2,
    },
    children: [
        {
            name: "version_list",
            path: "/version/list",
            component: "self",
            meta: {
                title: "版本列表",
                i18nTitle: "Routes.version.list",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
            },
        },
        {
            name: "version_language",
            path: "/version/language",
            component: "self",
            meta: {
                title: "多语言版本列表",
                i18nTitle: "Routes.version.language-list",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
            },
        },
    ],
}

export default pkg
