const pkg: Record<string, any> = {
    name: "marketplace",
    path: "/app-marketplace",
    component: "basic",
    meta: {
        title: "marketplace",
        i18nTitle: "Routes.marketplace.list",
        requiresAuth: true,
        keepAlive: true,
        icon: "fluent:book-information-24-regular",
        order: 2,
    },
    children: [
        {
            name: "marketplace_list",
            path: "/marketplace/list",
            component: "self",
            meta: {
                title: "marketplace",
                i18nTitle: "Routes.marketplace.list",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
            },
        },
    ],
}

export default pkg
